<template>
<div class="banner__wrapper">
  <div class="banner__main-info">
    <div class="banner__title">
      Знания экспертов <br> для <span>улучшения <br> качества твоей жизни</span>
    </div>
    <div class="banner__description">
      видеокурсы от сертифицированных экспертов, с практикой,
      на удобной онлайн-платформе. <br>Учитесь новому, узнавайте больше,<br> становитесь лучше
    </div>
    <router-link class="banner__btn" to="/courses">Выбрать подходящий курс</router-link>

  </div>
  <img class="banner__image" src="@/assets/img/user/home/banner-desktop.jpg" alt="">
</div>
</template>

<script>
export default {
  name: "Banner"
}
</script>

<style lang="scss" scoped>
.banner{

  &__wrapper{
    display: flex;
    align-items: center;
    background: #88A6D8;
    padding: 170px 0 90px 20px;
    min-height: calc(100vh - 260px);
    //height: 500px;
    transition: 0.2s;
  }

  &__main-info{
    flex: 1;
    max-width: 610px;
    margin: 0 auto;
  }
  &__title {
    font-family: Montserrat, sans-serif;
    font-size: 50px;
    font-weight: 600;
    line-height: 55px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-bottom: 22px;
    transition: 0.2s;

    span {
      color: #ffffff;
    }
  }
  &__description {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 36px;
    transition: 0.2s;
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: none;

    color: #FFFFFF;

    width: 329px;
    height: 61px;

    background: #000000;
    border-radius: 26px;

    cursor: pointer;
    transition: 0.2s;

    &:hover{
      opacity: (0.9);
    }
  }
  &__image {
    position: relative;
    right: -20px;
    max-width: 650px;
    min-width: 650px;
    object-fit: cover;
    transition: 0.2s;
    border-bottom-left-radius: 32px;
    border-top-left-radius: 32px;
    margin-left: 20px;
  }

  @media screen and (max-width: 1645px) {
    &__main-info {
      margin: 0 auto;
    }
    &__image {
      position: relative;
      left: 22px;
      transition: 0s;
    }
  }
  @media screen and (max-width: 1400px) {
    &__image {
      max-width: 590px;
      min-width: auto;
    }
  }

  @media screen and (max-width: 1200px) and (min-width: 920px) and (max-height: 1060px) {
    &__wrapper {
      min-height: 760px !important;
    }
  }
  @media screen and (max-width: 1200px){
    &__image{
      position: absolute;
      top: 500px;
      left: calc(100% - 600px);
      border-radius: 32px;
      max-width: 550px;
    }
    &__main-info {
      margin-left: 100px;
    }
    &__wrapper{
      align-items: flex-start;
      padding-top: 140px;
      min-height: calc(100vh - 340px);
      padding-left: 0;
    }
  }

  @media screen and (max-width: 1100px){
    &__image{
      left: 500px;
    }
  }


  @media screen and (max-width: 920px){
    &__image{
      position: relative;
      top: auto;
      right: 0;
      left: 0;
      width: 100%;
      max-width: 600px;
      margin: 40px auto 0 auto;
    }
    &__main-info {
      margin: 0 auto;
      flex: 0;
    }
    &__wrapper{
      flex-direction: column;
      padding: 140px 20px 60px 20px;
      min-height: calc(100vh - 200px);
    }
  }
  @media screen and (max-width: 555px) {
    &__wrapper {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &__title {
      font-size: 36px;
      line-height: 36px;
    }
    &__description {
      font-size: 18px;
      line-height: 20px;
    }
    &__btn {
      font-size: 14px;
      width: 260px;
    }
  }
  @media screen and (max-width: 485px) {
    &__title {
      font-size: 34px;
      line-height: 34px;
    }
    &__description {
      font-size: 17px;
      br:last-child {
        display: none;
      }
    }
  }
  @media screen and (max-width: 450px) {
    &__title {
      font-size: 32px;
      line-height: 32px;
    }
    &__description {
      font-size: 16px;
      line-height: 18px;
    }
    &__btn {
      font-size: 13px;
      width: 240px;
    }
  }
  @media screen and (max-width: 430px) {
    &__title {
      font-size: 30px;
      line-height: 30px;
    }
    &__description {
      font-size: 16px;
      line-height: 18px;
    }
  }
  @media screen and (max-width: 405px) {
    &__title {
      font-size: 28px;
      line-height: 28px;
    }
    &__description {
      font-size: 13px;
      line-height: 16px;
    }
    &__btn {
      font-size: 13px;
      width: 240px;
    }
  }

}
</style>