<template>
  <div class="home">
    <banner/>

    <div class="container">
      <categories/>
      <description/>
      <img class="home__icon-left" src="@/assets/img/user/home/element-left.svg" alt="">
      <img class="home__icon-right" src="@/assets/img/user/home/element-right.svg" alt="">
    </div>
  </div>
</template>

<script>
import Banner from "./components/Banner";
import Categories from "./components/Categories";
import Description from "./components/Description";

export default {
  name: "home",
  components: {
    Banner,
    Categories,
    Description
  }
}
</script>

<style lang="scss">
.home {
  display: flex;
  flex-direction: column;


  font-size: 24px;
  font-weight: 600;

  width: 100%;

  &__icon-left {
    position: absolute;
    left: 0px;
    top: 246px;

    z-index: -1;

    @media screen and (max-width: 1800px) {
      display: none;
    }
  }

  &__icon-right {
    position: absolute;
    right: 0px;
    top: 74px;

    z-index: -1;

    @media screen and (max-width: 1800px) {
      display: none;
    }
  }
}
</style>
